<template>
<loader v-bind="{ loading }">
    <columns>
      <column>
        <page-heading
          heading="Edit Checklist Classification"
        />
      </column>
      <column class="is-narrow">
        <div class="buttons">
            <router-link class="button is-small is-ghost" :to="{
                name: 'checklist-classifications'
            }">Back</router-link>
            <router-link 
            class="button is-small is-rounded" 
            :to="{
                name: 'create-checklist-template'
            }">
            <icon icon="plus"/>
            <span>Template</span>
        </router-link>
        </div>
      </column>
    </columns>

    <form>
        <columns>
            <column>
                <text-input
                    classes="is-medium is-rounded"
                    :error="$root.errors.name"
                    :value="checklist_classification.name"
                    @input="updateName">
                    Name
                </text-input>
            </column>
        </columns>
    
        <report-configuration />

        <columns>
            <column>
                <submit-button
                    class="is-medium is-rounded"
                    :working="working"
                    :disabled="deleting"
                    @submit="update">
                    Save
                </submit-button>
            </column>
            <column class="is-narrow">
                <action-button
                    class="is-small is-danger is-rounded"
                    :disabled="working"
                    :working="deleting"
                    @click="deleteClassification"
                    left-icon="trash">
                    Delete
                </action-button>
            </column>
        </columns>
    </form>
</loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import ReportConfiguration from './report-configuration/ReportConfig.vue'

export default {

    components: {
        ReportConfiguration
    },

    data: () => ({
        loading: false,
        working: false,
        deleting: false,
    }),

    async beforeCreate() {
        await this.$store.dispatch('team/loadTeamList')
        await this.$store.dispatch('checklistClassification/load', this.$route.params.checklistClassification)
    },

    beforeDestroy() {
        this.clearChecklistClassification()
    },

    methods: {
        ...mapMutations('checklistClassification', [
            'updateName',
            'clearChecklistClassification'
        ]),
        update() {
            this.working = true
            this.$store.dispatch('checklistClassification/update').then(() => {
                this.$toast.success('Classification saved')
                this.working = false
            }).catch(() => this.working = false)
        },
        async deleteClassification() {

            if(this.checklist_classification.checklists_count) {
                return this.$toast.error('This classification cannot be deleted because it has checklists that have been performed against it.')
            }

            if(await this.$confirm({
                title: 'Delete Checklist Classification',
                message: 'Are you sure you want to delete this classification? All associated inspections conducted will also be deleted.',
                confirmClass: 'is-danger'
            })) {
                this.deleting = true
                this.$store.dispatch('checklistClassification/deleteClassification').then(() => {
                    this.$toast.success('Successfully deleted')
                    this.$router.push({
                        name: 'checklist-classifications'
                    })
                    this.deleting = false
                }).catch(() => {
                    this.deleting = false
                    this.$toast.error('We were unable to delete this classification. Please contact support for assistance.')
                })
            }
        }
    },

    computed: {
        ...mapGetters('checklistClassification', [
            'checklist_classification'
        ])
    }

}
</script>